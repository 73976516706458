const { default: Image } = require("next/image");
const { default: Link } = require("next/link");
import styles from "@buddieshr/styles/components/appPill.module.scss";

const AppPill = ({
  product: p,
  withLink = false,
  style = {},
  className = "",
}) => {
  let content = (
    <div
      className={`${styles.topVignetteProducts} ${className}`}
      style={{
        backgroundColor: p.lighterColor,
        ...style,
      }}
      key={p.id}
    >
      <Image width={34} height={34} src={p.logoUrl} alt={p.alt} />
      <span className={styles.productTopText}>{p.verb}</span>
    </div>
  );

  if (withLink) {
    content = (
      <Link href={`/${p.slug}`} key={p.id}>
        {content}
      </Link>
    );
  }
  return content;
};

export default AppPill;
