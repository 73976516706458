import { Divider, Tag } from "antd";
import Link from "next/link";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "@buddieshr/styles/components/footer.module.scss";
import LogoApp from "./app/logoApp";
import AppPill from "./AppPill";
import { PRODUCTS } from "@buddieshr/utils/constants-products";
import LogoBuddiesHR from "./logoBuddies";
import {
  CalculatorOutlined,
  CoffeeOutlined,
  CompassOutlined,
  CrownOutlined,
  FormOutlined,
  LaptopOutlined,
  MessageOutlined,
  SmileOutlined,
} from "@ant-design/icons";

const USE_CASES = [
  {
    label: (
      <Link
        href={`/use-cases/celebrations`}
        title={"Celebrate milestones as a team"}
      >
        Team celebrations
      </Link>
    ),
    key: "celebrations",
    icon: <CrownOutlined />,
  },
  {
    label: (
      <Link
        href={`/use-cases/recognition`}
        title={"recognize employees in slack"}
      >
        Employee recognition
      </Link>
    ),
    key: "recognitions",
    icon: <SmileOutlined />,
  },
  // {
  //   label: "Employee onboarding",
  //   key: "onboarding",
  // },
  {
    label: (
      <Link
        href={`/use-cases/company-belonging`}
        title={"boosts company belonging"}
      >
        Company belonging
      </Link>
    ),
    key: "belonging",
    icon: <CompassOutlined />,
  },
  {
    label: (
      <Link
        href={`/use-cases/feedback`}
        title={"gather employee feedback in slack"}
      >
        Gather employee feedback
      </Link>
    ),
    key: "feedback",
    icon: <FormOutlined />,
  },
  {
    label: (
      <Link href={`/use-cases/remote`} title={"slack for remote companies"}>
        Remote company
      </Link>
    ),
    key: "remote",
    icon: <LaptopOutlined />,
  },
  {
    label: (
      <Link
        href={`/use-cases/measure-engagement`}
        title={"how to measure employee engagement in slack"}
      >
        Measuring engagement
      </Link>
    ),
    key: "measure",
    icon: <CalculatorOutlined />,
  },
  {
    label: (
      <Link
        href={`/use-cases/standup`}
        title={"Run async standup meetings in slack"}
      >
        Standup Meetings
      </Link>
    ),
    key: "standup",
    icon: <CoffeeOutlined />,
  },
  {
    label: (
      <Link
        href={`/use-cases/virtual-watercooler`}
        title={"what's the best virtual watercooler"}
      >
        Virtual Watercooler
      </Link>
    ),
    key: "watercooler",
    icon: <MessageOutlined />,
  },
];

const Footer = ({ app }) => {
  const { t } = useTranslation();

  const appList = (
    <>
      <div className={styles.Footer_section_title} style={{ marginTop: 84 }}>
        Employee engagement Apps
      </div>
      <div className={styles.apps}>
        {PRODUCTS.filter((r) => r.show)
          .filter((r) => r.show)
          .map((p) => (
            <AppPill key={p.id} product={p} withLink />
          ))}
      </div>
    </>
  );

  const footerSectionApp = (
    <div className={styles.Footer_section} style={{ paddingTop: "0px" }}>
      <div style={{ paddingBottom: "12px" }}>
        <LogoApp white app={app} withText />
      </div>
      <div className={styles.Footer_section_subtitle}>
        {app?.shortDescription}
      </div>
      <Divider />
      <div>
        {app?.fullName} is part of{" "}
        <Link
          style={{ display: "inline", textDecoration: "underline" }}
          href="/"
        >
          BuddiesHR
        </Link>
        .
      </div>
      {appList}
    </div>
  );
  const footerSectionBuddies = (
    <div className={styles.Footer_section} style={{ paddingTop: "0px" }}>
      <div style={{ paddingBottom: "12px" }}>
        <LogoBuddiesHR white />
      </div>
      <div className={styles.Footer_section_subtitle}>
        {t("descriptionContent")}
      </div>
      {appList}
    </div>
  );

  const resourcesBuddies = (
    <>
      <div>
        <Link
          className={styles.Footer_section_link}
          href="/best-slack-apps"
          title={"Best slack applications"}
        >
          Best Apps for Slack
        </Link>
      </div>
      <div>
        <Link
          className={styles.Footer_section_link}
          href="/startup-culture-insights-report-2024"
          title={"Startup Culture Insights Report [2024 edition]"}
        >
          Startup Culture Insights Report [2024 edition]
        </Link>
      </div>
      <div>
        <Link
          className={styles.Footer_section_link}
          href="/cost-of-turnover"
          title={"True cost of turnover"}
        >
          Turnover cost calculator
        </Link>
      </div>
      <div>
        <Link
          className={styles.Footer_section_link}
          href="/alternatives/simplepoll-polly-pulsy"
          title={"best survey apps for slack"}
        >
          Comparison Best survey App for Slack
        </Link>
      </div>
    </>
  );

  return (
    <footer className={styles.footer}>
      <div className={styles.Footer_rounded}></div>
      <div className={styles.Footer_wrapper}>
        <div className={styles.Footer_inner}>
          <div className={styles.Footer_content}>
            {app ? footerSectionApp : footerSectionBuddies}
            <div className={styles.Footer_section}>
              <div className={styles.Footer_section_title}>Use Cases</div>
              <div>
                {USE_CASES.map((u) => (
                  <div key={u.key} className={styles.useCaseLink}>
                    {u.icon || <></>}&nbsp;&nbsp;{u.label}
                  </div>
                ))}
              </div>
              {app?.alternativeLinks && (
                <div>
                  <br />
                  <br />
                  <div className={styles.Footer_section_title}>
                    Alternatives
                  </div>
                  {app.alternativeLinks.map((res) => (
                    <div key={res.title}>
                      <Link
                        className={styles.Footer_section_link}
                        href={res.href}
                        title={res.title}
                      >
                        {res.name}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={styles.Footer_section}>
              <div className={styles.Footer_section_title}>
                {t("Resources")}
              </div>
              <div>
                <Link
                  className={styles.Footer_section_link}
                  href="https://blog.buddieshr.com/"
                  title={"improve work experience"}
                  target={"_blank"}
                >
                  Blog
                </Link>
              </div>
              <div>
                <Link
                  className={styles.Footer_section_link}
                  href={`https://buddieshr.com/docs/${
                    app ? `${app.slug}` : ""
                  }`}
                  title={"Documentation BuddiesHR"}
                >
                  Documentation
                </Link>
              </div>
              {!app && resourcesBuddies}
              {app?.additionnalResources &&
                app.additionnalResources.map((res) => (
                  <div key={res.title}>
                    <Link
                      className={styles.Footer_section_link}
                      href={res.href}
                      title={res.title}
                    >
                      {res.name}
                    </Link>
                  </div>
                ))}
              <br />
              <br />
              <div className={styles.Footer_section_title}>{t("Company")}</div>
              <div>
                <Link
                  className={styles.Footer_section_link}
                  href="/"
                  title={"connect employees software"}
                >
                  {t("home")}
                </Link>
              </div>
              <div>
                <Link
                  className={styles.Footer_section_link}
                  href="/about"
                  title={"About buddieshr"}
                >
                  About
                </Link>
              </div>
              <div>
                <Link
                  className={styles.Footer_section_link}
                  href="/love"
                  title={"Wall of love buddieshr - Reviews and testimonials"}
                >
                  Wall of love ❤️
                </Link>
              </div>
              <div>
                <Link
                  className={styles.Footer_section_link}
                  href="/pricing"
                  title={"buddieshr cost"}
                >
                  Pricing
                </Link>
              </div>
              <div>
                <Link
                  className={styles.Footer_section_link}
                  href="/security"
                  title={"security details for BuddiesHR"}
                >
                  Security
                </Link>
              </div>
              <div>
                <Link
                  className={styles.Footer_section_link}
                  href="/support"
                  title={"Support BuddiesHR"}
                >
                  Support
                </Link>
              </div>
              <div>
                <Link
                  className={styles.Footer_section_link}
                  href="/terms-conditions"
                  title={`Terms and conditions ${app?.fullName}`}
                >
                  Terms and conditions
                </Link>
              </div>
              <div>
                <Link
                  className={styles.Footer_section_link}
                  href="/privacy"
                  title={`Privacy Policy ${app?.fullName}`}
                >
                  Privacy Policy
                </Link>
              </div>
              <div>
                <Link
                  className={styles.Footer_section_link}
                  href={"mailto:hey@buddieshr.com"}
                  title={t("contactus")}
                >
                  {t("contactus")}
                </Link>
              </div>
            </div>
          </div>
          <Divider
            style={{
              background: "#535353",
              marginTop: "112px",
              marginBottom: "62px",
            }}
          />
          <div className={`${styles.Footer_section_bottom}`}>
            {`${
              app ? app.fullName : "Buddieshr.com"
            } - All Rights Reserved - © Copyright 2024`}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
