import React from "react";
import styles from "@buddieshr/styles/components/byBuddiesHR.module.scss";

const ByBuddiesHR = ({ white = false }) => (
  <div className={`${styles.logoText}${white ? ` ${styles.white}` : ""}`}>
    by <span className={styles.buddies}>Buddies</span>HR
  </div>
);

export default ByBuddiesHR;
