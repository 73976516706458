import styles from "@buddieshr/styles/components/logoBuddies.module.scss";

const LogoBuddiesHR = ({ white = false }) => (
  <div className={styles.logoWrapper}>
    <span className={`${styles.logoText} ${white ? styles.logoWhite : ""}`}>
      <span className={styles.buddies}>Buddies</span>HR
    </span>
  </div>
);

export default LogoBuddiesHR;
