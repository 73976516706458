/* eslint-disable @next/next/no-img-element */
import React, { useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Button, Drawer, Menu } from "antd";
import styles from "@buddieshr/styles/components/mobileMenu.module.scss";
import Link from "next/link";

export default function MobileMenu({ app, items }) {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="secondary" onClick={showDrawer} className={styles.menuIcon}>
        <MenuOutlined />
      </Button>
      <Drawer
        title="Menu"
        placement="top"
        onClose={onClose}
        open={open}
        width={"100%"}
        height={"70%"}
        className={styles.drawerMobile}
      >
        <div className={styles.innerDrawer}>
          <div className={styles.mobileMenuInline}>
            <Menu mode="inline" className={styles.Mobile_menu} items={items} />
          </div>
          <div className={styles.bottomDrawer}>
            <div className={styles.bottomInner}>
              <Link
                href={"/get-started"}
                title={"get started for culture platform"}
                style={{ width: "100%" }}
              >
                <Button
                  block
                  size="large"
                  type="primary"
                  style={{ paddingLeft: 22, paddingRight: 22 }}
                >
                  Get Started
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
